<template>
  <div>
    Hola
  </div>
</template>

<script>
import DB from "@/modules/db";
export default {
  name: "Playground",
  async created() {
    const db = new DB();
    await db.drop();
    await db.setStorageMany([
      "country",
      "state",
      "city",
      "currency",
      "branch",
      "company",
      "workArea",
      "employee",
      "EPS",
      "AFP",
      "CCF",
      "ARL",
      "file",
      "target",
      "user",
      "standard",
      "summary",
      "payment",
      "invoice",
      "medicalEvaluation",
      "accident"
    ]);

    const country = await db.downloadStorage("public", "country", {
      where: "_id=" + localStorage.getItem("user.country")
    });

    Promise.all([
      new DB().downloadStorage("public", "ARL", {
        where: "countryCode=" + country[0].ISOCode
      }),
      new DB().downloadStorage("public", "EPS", {
        where: "countryCode=" + country[0].ISOCode
      }),
      new DB().downloadStorage("public", "AFP", {
        where: "countryCode=" + country[0].ISOCode
      }),
      new DB().downloadStorage("public", "CCF", {
        where: "countryCode=" + country[0].ISOCode
      }),
      new DB().downloadStorage("public", "state", {
        where: "countryCode=" + country[0].ISOCode
      }),
      new DB().downloadStorage("public", "city", {
        where: "countryCode=" + country[0].ISOCode
      }),
      new DB().downloadStorage("public", "currency", {
        where: "code=" + country[0].currency
      }),

      new DB().downloadStorage("optima", "company"),
      new DB().downloadStorage("optima", "user"),
      new DB().downloadStorage("optima", "branch"),
      new DB().downloadStorage("optima", "standard"),
      new DB().downloadStorage("optima", "summary"),
      new DB().downloadStorage("optima", "workArea"),
      new DB().downloadStorage("optima", "employee"),
      new DB().downloadStorage("optima", "file"),
      new DB().downloadStorage("optima", "target"),
      new DB().downloadStorage("optima", "accident"),
      new DB().downloadStorage("optima", "medicalEvaluation"),
      new DB().downloadStorage("subscription", "invoice"),
      new DB().downloadStorage("subscription", "payment", {
        where: "software=OPTIMA"
      })
    ]).then(() => {
      console.log("listo");
    });
  }
};
</script>
